var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    staticClass: "govuk-input",
    class: _vm.computedClass,
    attrs: {
      id: _vm.id,
      name: _vm.name,
      type: _vm.type,
      max: _vm.max,
      min: _vm.min,
      "aria-describedby": _vm.ariaDescribedBy
    },
    domProps: { value: _vm.value },
    on: {
      keyup: function($event) {
        if (
          !$event.type.indexOf("key") &&
          _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
        ) {
          return null
        }
        return _vm.onEnter.apply(null, arguments)
      },
      input: function($event) {
        return _vm.$emit("input", $event.target.value)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }